import React from 'react';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import SEO from '~components/seo';

const ConversationConfirmed = () => (
  <PageWrapper headline="Thank you for scheduling.">
    <SEO title="Thank you for Scheduling a Conversation" />
    <p>An Acacia Pharma representative will be reaching out to you as soon as possible to schedule a conversation about Byfavo.</p>
  </PageWrapper>
);

export default ConversationConfirmed;
